import { API_HOST, CLIENT_ID, CLIENT_SECRET } from '../services/config'
import { store } from '../store'
import { changeScreen, logOut, setAccessToken } from '../store/auth/auth-action'
import { setError } from '../store/impact/impact-action'
import { AccessToken } from './../types/api'

const originalRequest = async (url: string, config: any) => {
    url = `${API_HOST}${url}`
    const response = await fetch(url, config)
    const data =
        response.statusText == 'No Content' ? null : await response.json()
    return { response, data }
}

const refreshToken = async (authTokens: AccessToken) => {
    const body = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: authTokens.refresh_token,
        scope: 'ios',
    }

    const response = await fetch(`${API_HOST}/oauth/v2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    if (response.status === 200) {
        const data = await response.json()
        if (data.access_token && data.refresh_token) {
            store.dispatch(setAccessToken(data))
        }
        return data
    } else {
        store.dispatch(changeScreen('Start Screen'))
        store.dispatch(logOut())
    }
}

const customFetcher = async (url: string, config: any) => {
    let authTokens = store.getState().auth.access_token
    let { response, data } = await originalRequest(url, config)
    if (response.statusText === 'Unauthorized') {
        authTokens = await refreshToken(authTokens)
        config['headers'] = {
            ...config['headers'],
            Authorization: `Bearer ${authTokens?.access_token}`,
        }
        const newResponse = await originalRequest(url, config)
        response = newResponse.response
        data = newResponse.data
    }
    if (response.status >= 500 && response.status < 600) {
        store.dispatch(setError(true))
    }
    store.dispatch(setError(false))
    return { response, data }
}
export default customFetcher

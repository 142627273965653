import { useNavigate } from 'react-router-dom'
import icon from '../../assets/images/svgs/loggin-button.svg'
import menuSvg from '../../assets/images/svgs/menu.svg'
import person from '../../assets/images/svgs/person.svg'
import headerLogo from '../../assets/images/svgs/header-logo.svg'
import { LanguageDropdown } from '../language-dropdown'
import { Button } from '../button'
import { useTranslation } from 'react-i18next'

import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import './styles.scss'
import { HeaderMenuMobile } from '../header-menu-mobile'
import { useState } from 'react'
import MenuLinks from '../menu-links'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'
import i18n from '../../i18n'

export const Header = () => {
    const dispatch = useAppDispatch()
    const { user, screen } = useAppSelector((state) => state.auth)
    const navigate = useNavigate()
    const pathLang = getCurrentLanguagePath()
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
    const handleCloseMobileMenu = () => setIsOpenMobileMenu(false)
    const handleOpenMobileMenu = () => setIsOpenMobileMenu(true)

    const { t } = useTranslation()

    const _handlePress = () => {
        if (
            screen === 'Corporate Details' ||
            screen === 'Corporate Details Slider'
        ) {
            dispatch(changeScreen('Menu Screen'))
        }
        navigate(`${pathLang}/menu`)
    }

    return (
        <div className="header">
            <div className="header__header-container">
                <button
                    onClick={handleOpenMobileMenu}
                    className="header__mobile-menu-button"
                >
                    <img src={menuSvg} alt="" />
                </button>
                <a className="header__logo-link" href={`https://www.pai.social/${pathLang}`}>
                    <img src={headerLogo} className="header__logo" alt="" />
                </a>
                <div className="header__menu">
                    <MenuLinks/>
                </div>
                <div className="header__right-content">
                    <LanguageDropdown isMobile={false} />
                    <div
                        className={`header__auth-container ${
                            !user ? 'login' : ''
                        }`}
                    >
                        <Button
                            text={user ? null : t("log_in")}
                            image={user ? user.profile_photo.url : person}
                            hideImg={!user}
                            variant="transparent"
                            onClick={_handlePress}
                            icon={user ? icon : ''}
                        />
                    </div>
                </div>
            </div>
            <HeaderMenuMobile
                _handlePress={_handlePress}
                isOpen={isOpenMobileMenu}
                onClose={handleCloseMobileMenu}
            />
        </div>
    )
}

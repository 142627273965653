import * as types from './types'

export const setFollowings = (data: types.Followings) => ({
    type: types.SET_FOLLOWINGS,
    payload: data,
})
export const setIsInScreen = (is: boolean) => ({
    type: types.IS_IN_SCREEN,
    payload: is,
})
export const _Unfollow = (id: string) => ({
    type: types.UNFOLLOW,
    payload: id,
})

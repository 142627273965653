import React from 'react'
interface Props {
    text: string
    disabled? : boolean 
    onClick: () => void
}
export const Menu_buttons: React.FC<Props> = ({ text, onClick, disabled = false }) => {
    return (
        <div onClick={onClick}>
            <button disabled={disabled} className="menu__buttons">
                <div style={{color: disabled ? "#a2a2a2" : ""}} className="menu__buttons__text">{text}</div>
            </button>
        </div>
    )
}

import { AccessToken, User } from '../../types/api'
import * as types from './types'

export const changeScreen = (screen: string) => ({
    type: types.CHANGE_SCREEN,
    payload: screen,
})

export const setAccessToken = (access_token: AccessToken) => ({
    type: types.SET_ACCESS_TOKEN,
    payload: access_token,
})

export const setUserInfo = (user: User) => ({
    type: types.SET_USER_INFO,
    payload: user,
})

export const logOut = () => ({
    type: types.LOGOUT,
})

import SettingsHeader from '../../components/settings-header'
import { useTranslation } from 'react-i18next'
import './styles.scss'

const TermsOfUse = () => {
    const { t } = useTranslation()

    return (
        <div className="terms-of-use__container">
            <SettingsHeader screen="Settings" text={t('terms_of_use')} />
            <article className="terms-of-use__content">
                <p>
                    Hello, welcome to PAI&apos;s Terms and Conditions of Use
                    (these &quot;Terms&quot;). This is a contract between JAMS
                    Design Consultancy BV, trading as PAI (&quot;Company&quot;,
                    &quot;we&quot;, &quot;us&quot; or &quot;our&quot; ), and you
                    (&quot;you&quot;, &quot;your&quot; or &quot;user&quot;), the
                    person or entity that registers for or downloads our
                    proprietary ecommerce application (&quot;Application&quot;),
                    or the organisation or entity that has authorised you to
                    register for the Application for its benefit, and governs
                    your access to and use of the Application. We want you to be
                    aware of your rights before using our services. Please read
                    these Terms &amp; Conditions carefully, as they affect your
                    legal rights. This is a legally binding agreement between
                    you and PAI. By downloading or registering for the
                    application, you confirm that (1) you have read and
                    understood the terms, (2) you are at least 18 years old, and
                    (3), you and the organisation or entity on whose behalf you
                    are accepting these terms, accept these terms in electronic
                    form. If you do not agree to be bound by these Terms &amp;
                    Conditions, you should not register for or download the
                    application.
                </p>

                <h2>Content & Intellectual property</h2>
                <p>
                    The PAI application provided to you under these Terms, and
                    all content provided by or included with the Application and
                    any application program interface that we provide to you
                    (“API”), and all documentation that we make available to you
                    in connection with the foregoing (collectively the
                    “Application”), are and will remain the exclusive property
                    of PAI and its licensors. In these Terms & Conditions,
                    Content means any text, graphics, images, audio, video,
                    software, data compilations, page layout, underlying code
                    and software and any other form of information capable of
                    being stored, including, but not limited to any such content
                    uploaded by Users. By continuing to use our application you
                    acknowledge that such Content is protected by copyright,
                    trademarks, database rights and other intellectual property
                    rights. As a user of the Application, we grant you a
                    limited, non-transferable, non-sublicensable, non-exclusive
                    and revocable license to access and use the Application
                    solely as permitted by and subject to these Terms. Except
                    for this limited license, we do not grant you any other
                    rights or licenses with respect to the Application, and such
                    rights and licenses are expressly reserved to us and our
                    licensors. You must not otherwise reproduce, modify, copy,
                    distribute or use for commercial purposes any Content
                    without the written permission of PAI. All right, title and
                    interest in and to Our Content remains with us at all times.
                </p>

                <h2>Use of Application</h2>

                <p>
                    You may use the Application solely for lawful business
                    purposes, and solely in connection with the online merchant
                    website identified in your online registration form
                    (“Store”). You may permit customers of your Store
                    (“Customers”) to use the Application in connection with
                    their purchases through your Store, provided that you
                    require such Customers to comply with terms and conditions
                    of use of the Store that are at least as protective of the
                    Application and our rights to the Application as set forth
                    in these Terms, including without limitation paragraph 15
                    below. You are responsible and liable for your Users&apos;
                    use of the Application, and for their compliance (and
                    noncompliance) with these Terms. If the Application is made
                    available to you through a third party such as Shopify, then
                    you must comply with all Shopify requirements and agreements
                    to which you are bound.
                    <br />
                    <br />
                    We may monitor any and all use of the Application by all
                    users, including Customers. We may gather user and customer
                    system data for the purpose of optimizing the Application,
                    and may use and disclose this data subject only to our
                    compliance with applicable laws.
                    <br />
                    <br />
                    We may modify or remove any features of the Application at
                    any time and for any reason, with or without notice.
                </p>

                <h2>Conduct on the Application</h2>
                <p>
                    You may not, use or allow others to use, the Application in
                    any manner other than as expressly allow in this Agreement.
                    You agree not to (i) reverse engineer, decompile,
                    disassemble, re-engineer or otherwise create or attempt to
                    create or permit, allow, or assist others to create the
                    source code of the Application or its structural framework,
                    (ii) sublicense, subcontract, translate or sell any rights
                    to the Application, (iii) use any robot, spider, site search
                    or retrieval mechanism or other manual or automatic device
                    or process to retrieve, index, data mine, or in any way
                    reproduce or circumvent the navigational structure or
                    presentation of the Application, (iv) harvest or collect
                    information about or from other users of the Application (v)
                    probe, scan or test the vulnerability of the Application,
                    nor breach the security or authentication measures on the
                    Application or take any action that imposes an unreasonable
                    or disproportionately large load on the infrastructure of
                    the Application (vi) modify or create derivative works of
                    the Application, (vii) attempt to gain unauthorized access
                    to the Application or its related systems or networks,
                    (viii) use the Application in whole or in part for any
                    illegal or other purpose except as expressly provided under
                    this Agreement (including without limitation allowing any
                    distribution or sublicense of the Application or other
                    access to the Application by any person or entity that is
                    not a User), or (ix) facilitate or encourage any violations
                    of this Section. You agree to (a) take all reasonable
                    precautions to prevent unauthorized or improper use of the
                    Application, (b) not interfere with or disrupt the integrity
                    or performance of the Application, (c) not attempt to gain
                    unauthorized access to the Application or its related
                    systems or networks, and (d) not create Internet “links” to
                    the Application or “frame” or “mirror” any content therein.
                    You agree not to access the Application by any means other
                    than through the interface that is provided by us for use in
                    accessing the Application.
                    <br />
                    <br />
                    In addition, while using the Application, you will not do
                    the following:
                </p>
                <ul>
                    <li>
                        Violate any laws, third-party rights or these Terms;
                    </li>
                    <li>
                        Transmit any unlawful, threatening, libelous,
                        defamatory, obscene, indecent, inflammatory or profane
                        material or any material that could constitute or
                        encourage conduct that would be considered a criminal
                        offense, give rise to civil liability or otherwise
                        violate any law;
                    </li>
                    <li>
                        Upload or distribute any virus, Trojan horse, time bomb
                        or other malicious code or technologies that may damage,
                        interfere with or harm, or attempt to damage, interfere
                        with or harm, the Application, the operation of the
                        Application or the interests or property of its users;
                        or
                    </li>
                    <li>
                        Use the Application to send altered, deceptive or false
                        source-identifying information.
                    </li>
                    <li>
                        You are responsible for obtaining your own access to the
                        Application, and such access may involve third-party
                        fees, such as internet service provider or airtime
                        charges. You are responsible for those fees. In
                        addition, you must provide and are responsible for all
                        equipment necessary to access the Application.
                    </li>
                    <li>
                        We may identify you as a customer of PAI and use your
                        name and logo in our advertising and marketing materials
                        (including our website and press releases) solely in
                        connection with such identification. You can retract the
                        foregoing permission by submitting a request via email
                        to support@PAI.earth
                    </li>
                </ul>

                <h2>Accounts</h2>
                <p>
                    As a condition to using certain features of the Application,
                    you will be required to create an account (an “Account”),
                    which Account will include a username and password. If you
                    create an Account, you agree to provide true, accurate,
                    complete and updated registration information and maintain
                    and promptly update your registration information to keep it
                    true, accurate, complete and updated. If you provide
                    information that is untrue, inaccurate, incomplete or not
                    current, or we have reasonable grounds to suspect that such
                    information is untrue, inaccurate, incomplete or not
                    current, we have the right to suspend or terminate your
                    Account and refuse any and all current or future use of
                    related features of the Application.
                </p>
                <br />
                <p>
                    You are responsible for maintaining the confidentiality of
                    your Account username and password and for the security of
                    your Account. You are also fully responsible for all
                    activities that occur under your Account. You must notify us
                    immediately of any actual or suspected loss, theft or
                    unauthorized use of your Account username or password. We
                    are not liable for any loss or damage arising from your
                    failure to comply with this Section.
                </p>
                <br />
                <p>
                    If you have registered and created an Account on the
                    Application, and you wish to close such Account, you may
                    contact us at the following email address to request
                    termination and deletion of your Account: support@pai.social{' '}
                </p>

                <h2>Purchases</h2>
                <p>
                    The Application may employ the use of third-party services
                    for the purpose of facilitating payment and the completion
                    of purchases. By submitting your information, you grant us
                    the right to provide the information to these third parties.
                </p>
                <br />
                <p>
                    Shopify will automatically charge your credit card for
                    pension contribution purchases made through the Application.
                    If your payment lapses, and PAI do not receive funds, we are
                    not liable or responsible to purchase pension products on
                    users&apos; behalf. Also, if your payment lapses, then,
                    without limiting PAI&apos;s other available remedies, we may
                    suspend your access to the Application, treat such lapse as
                    a material breach of these Terms, and/or assess a late
                    payment fee of 5% per month on the overdue amount or the
                    highest amount permitted under applicable law, whichever is
                    less.
                </p>

                <h2>Disclaimer / Liability</h2>
                <p>
                    Your use of the Application, including, without limitation,
                    your use of any content accessible through the application
                    and your interactions and dealing with any service users is
                    at your sole risk. The service and all content available on
                    and through the application or service are provided on an
                    &apos;as is&apos; and &apos;as available&apos; basis. PAI
                    and its suppliers and licensors expressly disclaim all
                    warranties of any kind, whether express or implied,
                    including but not limited to the implied warranties of
                    merchantability, fitness for particular purpose, title, and
                    non-infringement. PAI does not warrant uninterrupted use or
                    operation of the service or your access to any content. No
                    advice or information, whether oral or written, obtained by
                    you from the service will create any warranty regarding PAI
                    that is not expressly stated in these terms. Some
                    jurisdictions may prohibit a disclaimer of warranties and
                    you may have other rights that vary from jurisdiction to
                    jurisdiction.
                </p>

                <h2>Third-party content and Links to other websites</h2>

                <p>
                    Portions of the content and information provided on the
                    Application may have been compiled from or posted by
                    third-party sources, and the Application may contain links
                    to third-party websites or services that are not owned or
                    controlled by PAI. PAI has no control over, and assumes no
                    responsibility for, the content, privacy policies or
                    practices of any third-party content, websites or services.
                    We do not warrant the offerings of any third parties or
                    their websites. Further, we do not warrant the accuracy,
                    timeliness or appropriateness for any particular purpose of
                    any third-party content or content on an outside website.
                    Inclusion on the Application of any third party, third-party
                    content or link to an outside website does not constitute
                    any endorsement by PAI of the third party, any third-party
                    content included on the Application, the third-party website
                    or any of the content, products or services provided by a
                    third party on any linked websites
                </p>

                <br />

                <p>
                    We shall not be responsible or liable, directly or
                    indirectly, for any damage or loss caused or alleged to be
                    caused by or in connection with use of or reliance on any
                    third-party content, goods or services available on or
                    through any such third-party websites or services, which you
                    use at your own risk. We strongly advise you to read the
                    terms and conditions and privacy policies of any third-party
                    websites or services that you use or visit.
                </p>

                <h2>Indemnification</h2>
                <p>
                    You will indemnify and hold harmless PAI, its affiliates and
                    licensors, and their respective employees, contractors,
                    agents, shareholders, officers and directors (the
                    “Indemnified Parties”) from and against any and all claims,
                    damages, obligations, losses, liabilities, suits,
                    settlements, judgments, costs, fines, penalties, debts and
                    expenses (including, but not limited to, attorneys&apos;
                    fees) (“Claims”) resulting from or arising out of (i) misuse
                    of the Application or use other than in accordance with
                    these Terms by you or Users, (ii) breach of these Terms by
                    you or Users, (iii) violation of any law or the rights of a
                    third party by you or Users, (iv) content you or Users
                    submit, transmit or otherwise make available through the
                    Application, (v) claims or allegations made by Users, and
                    (vi) all matters relating to the Store. You will also defend
                    the Indemnified parties from Claims at your expense, with
                    counsel reasonably acceptable to us, if instructed by us.
                    The Indemnified Parties shall be entitled to participate in
                    the defense of any such Claim without waiving or reducing
                    any of your obligations under this Section. You shall also
                    indemnify the Indemnified Parties for any expenses incurred
                    in enforcing this Section.
                </p>

                <h2>Confidentiality and Data Protection</h2>
                <p>
                    PAI will maintain confidentiality of your data, and will
                    make reasonable efforts to prevent any unauthorised use,
                    disclosure, copying, publication or dissemination of your
                    data, except and only to the extent necessary in accordance
                    with these Terms & Conditions. In these Terms & Conditions,
                    “Personal Data” means any information relating to an
                    identifiable person who can be directly or indirectly
                    identified in particular by reference to an identifier.
                    Wherever PAI determines the means and purposes of the
                    processing of Personal Data relating to you, PAI will be the
                    &apos;data controller&apos; (as such term is understood under
                    applicable data protection laws) of such Personal data, and
                    the terms of PAI&apos;s Privacy Notice shall apply to such
                    processing. PAI will in accordance with its Privacy Notice:
                    a. comply at all times with applicable privacy and data
                    protection laws; b. take all reasonable steps to protect the
                    security and integrity of (i) information that can be used
                    to establish the identity of you, (ii) sensitive information
                    about you and (iii) your Personal Data; and c. take
                    reasonable steps to notify you if PAI becomes aware of any
                    disclosure of (i) your Personal Data or other data that can
                    be used to establish the identity of you, or (ii) sensitive
                    information about you that is associated with you.
                </p>

                <br />

                <p>
                    By using our services, you grant to us and our Trusted
                    Partners a non-exclusive, royalty-free licence to use the
                    information in your Personal Account(s) for all purposes
                    connected with the Service or referred to in these Terms or
                    the Privacy Policy, with the right to use, modify, display,
                    and create new material using or incorporating such
                    information to provide the Service to you. By submitting
                    information, you agree (without the payment of any fees),
                    that our Trusted Partners and us may use the information for
                    the purposes set out above.
                </p>

                <br />

                <p>
                    We do not check the accuracy of the Personal Accounts
                    information and personal information you provide to us and
                    we rely on you and your Personal Account providers to ensure
                    that the Personal Accounts information and personal
                    information you provide to us is up to date and accurate.
                </p>

                <br />

                <p>
                    For our compliance purposes and in order to provide the
                    Service to you, you hereby authorise us to, directly or
                    through a third-party, obtain, verify, and record
                    information and documentation that helps us verify your
                    identity and Personal Account information. When you register
                    for the Service and from time to time thereafter, we may
                    require you to provide and/or confirm information and
                    documentation that will allow us to identify you.
                </p>

                <br />

                <p>
                    You agree that we will be entitled to disclose your identity
                    and information relating to your Personal Account(s) to
                    third parties if we are required to do so by any applicable
                    law or court order. For more information about how PAI
                    collects, uses, and shares your personal data, please check
                    out our Privacy Policy.
                </p>

                <h2>Suspension and Termination</h2>

                <p>
                    We may terminate or suspend your access to the Application
                    or parts thereof at any time, without prior notice or
                    liability, in our sole discretion, for any reason or no
                    reason. Upon termination of your access to the Application
                    or any part thereof, your right to use or access the
                    Application, or such relevant part of the Application, will
                    immediately cease. Termination of your access to the
                    Application or any part thereof shall not relieve you of any
                    obligations arising or accruing prior to such termination or
                    limit any liability that you may otherwise have to us or any
                    third party. You agree that we shall not be liable to you or
                    any third party for any termination of your access to the
                    Application pursuant to this Section. All provisions of
                    these Terms which by their nature should survive
                    termination, shall survive termination, including, without
                    limitation, ownership provisions, warranty disclaimers,
                    indemnity provisions and limitations of liability.
                </p>

                <h2>Governing Law and Jurisdiction. </h2>
                <p>
                    This Agreement and any non-contractual disputes arising out
                    of or in connection with it (including a dispute as to the
                    existence or enforceability of this Agreement) (each a
                    “Dispute”) is governed by Dutch law. The courts of The
                    Netherlands have exclusive jurisdiction to determine any
                    Dispute and no Party shall commence proceedings in relation
                    to any Dispute in any other forum.{' '}
                </p>

                <h2>General</h2>
                <p>
                    PAI reserves the right at any time, or from time to time to
                    update, revise, supplement or otherwise modify these Terms &
                    Conditions and to impose new or additional terms. All
                    updates, revisions, modifications and new rules will be
                    effective immediately and incorporated into these Terms &
                    Conditions. Your continued use of PAI following any Change
                    constitutes your acceptance of the Change and you will be
                    legally bound by the new updated Terms. If you do not accept
                    any Changes to the Terms, you should stop using PAI
                    immediately. You may not transfer any of your rights under
                    these Terms & Conditions to any other person or entity. We
                    may transfer our rights under these Terms & Conditions where
                    we reasonably believe your rights will not be affected.
                </p>

                <br />
                <p>
                    These Terms and Conditions together with the Privacy Notice
                    and Cookies Policy contain the whole agreement between the
                    parties relating to its subject matter and supersede all
                    prior discussions, arrangements or agreements that might
                    have taken place in relation to the Terms and Conditions.
                </p>
                <br />

                <p>
                    You and PAI are independent contractors and nothing in these
                    Terms will be deemed to create any agency, employee-employer
                    relationship, partnership, or joint venture between you and
                    PAI. Neither party will have or represent that such party
                    has the right, power or authority to bind, contract or
                    commit the other party or to create any obligation on behalf
                    of the other party. All notices and consents required or
                    permitted under these Terms must be in writing; must be
                    personally delivered, sent by registered or certified mail
                    (postage prepaid), by overnight courier, or by email.
                    Notices to PAI should be sent to support@pai.social .
                    Notices to you shall be sent to the address that we have on
                    file with your user account. You agree that we may
                    communicate directly with Users electronically regarding
                    administrative, security and other issues relating to their
                    use of the Application. Notwithstanding anything to the
                    contrary herein, any notices, agreements, disclosures or
                    other communications that we send you electronically will
                    satisfy any legal communication requirements, including that
                    such communications be in writing. The foregoing does not
                    affect your statutory rights. If any provision in these
                    Terms is held by a court of law to be illegal, invalid, or
                    unenforceable, the legality, validity, and enforceability of
                    the remaining provisions of these Terms will not be affected
                    or impaired thereby and the illegal, invalid, or
                    unenforceable provision will be deemed modified such that it
                    is legal, valid, and enforceable and accomplishes the
                    intention of the parties to the fullest extent possible.
                </p>

                <h2>Enquiries</h2>
                <p>
                    If you have an enquiry or complaint about the Service, or
                    simply wish to get in touch, you should contact our customer
                    services team via our email support@pai.social
                </p>
            </article>
        </div>
    )
}

export default TermsOfUse

import React from 'react'

import './styles.scss'

interface Prop {
    value: string
    setValue: (arg: string) => void
    label?: string
}

const TextField: React.FC<Prop> = ({ value, setValue, label }) => {
    return (
        <div className="input-container">
            <input
                className="input-container_input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={label}
            />
        </div>
    )
}

export default TextField

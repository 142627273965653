import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import langSvg from '../../assets/images/svgs/lang.svg'

import './styles.scss'

type Language = {
    code: string
    name: string
    label: string
}

const languages: Language[] = [
    { code: 'en', name: 'ENG', label: 'ENGLISH' },
    { code: 'se', name: 'SE', label: 'SVENSKA' },
    { code: 'nl', name: 'NL', label: 'NEDERLANDS' },
]

interface Props {
    isMobile: boolean
}

export const LanguageDropdown = ({ isMobile }: Props) => {
    const { i18n } = useTranslation()
    const navigate = useNavigate()
    const { lang } = useParams<{ lang: string }>()

    
    const getLangFromPath = (): string | undefined => {
        const currentPath = window.location.hash; 
        const pathSegments = currentPath.split('/').filter(Boolean);
        const firstSegment = pathSegments[1]; 
    
        if (languages.some((language) => language.code === firstSegment)) {
            return firstSegment;
        }
    
        return undefined;
    }
    
    const getInitialLang = (): Language => {
        const currentLang = getLangFromPath() || lang || i18n.language;
        const savedLang =
            languages.find((language) => language.code === currentLang) ||
            languages[0];
    
        i18n.changeLanguage(savedLang.code);
        return savedLang;
    }

    const [selectedLang, setSelectedLang] = useState<Language>(getInitialLang)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleLanguageChange = (lang: Language) => {
        if (lang.code !== selectedLang.code) {
            setSelectedLang(lang)
            i18n.changeLanguage(lang.code)

            const langPath = lang.code === 'en' ? '' : `/${lang.code}`;
            navigate(langPath);
        }
        setIsDropdownOpen(false)
    }

    return (
        <div
            style={{
                borderRadius: isMobile && isDropdownOpen ? '0px' : '20px',
            }}
            className={`language-dropdown ${
                isMobile
                    ? 'language-dropdown-mobile'
                    : 'language-dropdown-desktop'
            }`}
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
        >
            <div
                className={`${
                    isMobile && 'language-dropdown__button-mobile'
                } language-dropdown__button`}
            >
                <img src={langSvg} alt="Language" />
                <h5>{selectedLang.name}</h5>
                <div className="arrow-down"></div>
            </div>
            {isDropdownOpen && (
                <ul className="language-dropdown__list">
                    {languages.map((lang) => (
                        <li
                            key={lang.code}
                            onClick={() => handleLanguageChange(lang)}
                        >
                            {lang.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

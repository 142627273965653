import { useTranslation } from 'react-i18next'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'

const MenuLinks = () => {
    const { t } = useTranslation()
    let pathLang = getCurrentLanguagePath()

    pathLang = pathLang && pathLang + "/"

    return (
        <div>
            <a href={`https://www.pai.social/${pathLang}`}>{t('home')}</a>
            <a className="active-item" href="">
                {t('shop')}
            </a>
            <a href={`https://www.pai.social/${pathLang}savers`}>
                {t('savers')}
            </a>
            <a href={`https://www.pai.social/${pathLang}partners`}>
                {t('partners')}
            </a>
        </div>
    )
}

export default MenuLinks

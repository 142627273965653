import { Project } from '../../types/api'
import * as types from './types'

export const setImpacts = (data: Array<Project>) => ({
    type: types.SET_IMPACTS,
    payload: data,
})

export const setActiveProject = (project: Project) => ({
    type: types.SET_ACTIVE_PROJECT,
    payload: project,
})
export const setActiveCorporateId = (id: string) => ({
    type: types.SET_CORPORATE_ID,
    payload: id,
})
export const setNumberButton = (number: number) => ({
    type: types.SET_NUMBER_BUTTON,
    payload: number,
})
export const setError = (isError: boolean) => ({
    type: types.SET_ERROR,
    payload: isError,
})
export const setInProject = (isInProject: boolean) => ({
    type: types.SET_IN_PROJECT,
    payload: isInProject,
})
export const setMoreInfo = (isMore: boolean) => ({
    type: types.SET_MORE_INFO,
    payload: isMore,
})

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBack_Icon from '../../assets/images/svgs/arrow-back-black.svg'
import follow from '../../assets/images/svgs/follow.svg'
import More from '../../assets/images/svgs/more.svg'
import unfollow from '../../assets/images/svgs/unfollow.svg'
import { Button } from '../../components/button'
import Percentage from '../../components/percentage'
import {
    followFeatures,
    getCorporateDetailsByName,
    unfollowFeatures,
} from '../../services/api'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setMoreInfo, setNumberButton } from '../../store/impact/impact-action'
import { Accreditations, CorporatePartner } from '../../types/api'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'
import './styles.scss'
import i18n from '../../i18n'

const Corporate_Details = () => {
    const params = useParams()
    const { isMore } = useAppSelector((state) => state.impact)
    const { access_token } = useAppSelector((state) => state.auth)
    const { t } = useTranslation()
    const { inScreen } = useAppSelector((state) => state.following)
    const dispatch = useAppDispatch()
    const [details, setDetails] = useState<CorporatePartner>(
        {} as CorporatePartner
    )
    const [loaded, setloaded] = useState<boolean>(false)
    const [secondMore, setSecondMore] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [favorite, setFavorite] = useState<boolean>(false)
    const navigate = useNavigate()
    const pathLang = getCurrentLanguagePath()

    const _getCorporateDetails = async () => {
        setSecondMore(false)
        setloaded(true)

        const [brandName, countryCode] = params.brandName!.split('__')
        const country = countryCode ? '/' + countryCode : ''

        const data: CorporatePartner = await getCorporateDetailsByName(
            access_token.access_token,
            brandName.replaceAll('_', ' ') + country
        )
        setloaded(false)
        setDetails(data)
        setFavorite(data.is_favorite!)
    }
    const _more = () => {
        dispatch(setMoreInfo(true))
        setSecondMore(true)
    }
    const _follow = async () => {
        if (!favorite) {
            await followFeatures(access_token.access_token, details.id)
        } else {
            await unfollowFeatures(access_token.access_token, details.id)
        }
        setFavorite(!favorite)
    }
    const _openAccreditations = (link: string) => {
        window.open(link, '_blank')
    }
    const _hideInfoModal = () => {
        if (secondMore) {
            dispatch(setMoreInfo(true))
        }
        setShowInfo(false)
    }
    const _showInfoModal = () => {
        dispatch(setMoreInfo(false))
        setShowInfo(true)
    }
    const _openSlider = (e: number) => {
        dispatch(setNumberButton(e))
        dispatch(changeScreen('Corporate Details Slider'))
    }
    const _closeModal = () => {
        navigate(`/${pathLang === '' ? pathLang : pathLang + '/'}menu`, {
            replace: true,
        })

        if (inScreen) {
            dispatch(changeScreen('Following'))
        } else {
            dispatch(changeScreen('Menu Screen'))
        }
        dispatch(setMoreInfo(false))
    }

    const _getLink = async (link: string) => {
        const newLink =
            !link.startsWith('www.') && !link.startsWith('https://')
                ? 'https://' + link
                : link.startsWith('www')
                ? link.replace('www.', 'https://')
                : link
        window.open(newLink, '_blank')
    }
    useEffect(() => {
        _getCorporateDetails()
    }, [params.brandName])

    const proofPointsFoundList =
        details?.last_signed_off_worker?.proof_points_found_list
    const isLogosProofPointsFoundList =
        proofPointsFoundList &&
        proofPointsFoundList.filter((e) => e.proof_points?.logo?.url).length > 0

    useEffect(() => {
        const description = details?.description
        if (!description || description.length < 400) {
            dispatch(setMoreInfo(true))
        } else {
            dispatch(setMoreInfo(false))
        }
    }, [details.description])

    return (
        <div className="corporate-details">
            <div className="corporate-details__header">
                <div
                    className="corporate-details__backarrow"
                    onClick={_closeModal}
                >
                    <img src={ArrowBack_Icon} />
                </div>
            </div>
            <div
                style={{
                    backgroundImage:
                        details.photo?.url && !details.hide_background_image
                            ? `url(${details.photo.url})`
                            : 'none',
                }}
                className="corporate-details__image-container"
            >
                {(details.hide_background_image || !details.photo) &&
                    details.logo?.url &&
                    !details.hide_logo && (
                        <img
                            className="corporate-details__logo"
                            src={details.logo.url}
                            alt={details.name}
                        />
                    )}

                {!details?.hide_name && details.name && (!details.logo || details.hide_logo) && (
                    <div
                        style={{
                            color:
                                details.photo?.url &&
                                !details.hide_background_image
                                    ? `#fff`
                                    : `#000`,
                        }}
                        className="corporate-details__photo-name"
                    >
                        {details?.name}
                    </div>
                )}
            </div>
            <div className="corporate-details__follow-percent">
                <div onClick={_follow} className="corporate-details__follow">
                    <img src={favorite ? unfollow : follow} />
                    <p className="corporate-details__follow_text">
                        {favorite ? t('following') : t('follow')}
                    </p>
                </div>
                <div
                    className={
                        details?.affiliate_percent
                            ? 'corporate-details__percent'
                            : 'corporate-details__percent-false'
                    }
                >
                    <p
                        className="corporate-details__percent_text"
                        onClick={_showInfoModal}
                    >
                        {details?.commission_type === 'percentage'
                            ? details?.affiliate_percent + '%'
                            : details?.affiliate_percent +
                              ' ' +
                              details.commission_currency}
                    </p>
                </div>
            </div>
            <div
                className={
                    isMore
                        ? 'corporate-details__description_true'
                        : 'corporate-details__description_false'
                }
                dangerouslySetInnerHTML={{ __html: details?.description }}
            ></div>
            {!isMore && (
                <div
                    className="corporate-details__description__more"
                    onClick={_more}
                >
                    <p>{t('more').toUpperCase()}</p>
                    <img src={More} />
                </div>
            )}
            <div className="corporate-details__button">
                <Button
                    variant="white-outlined"
                    text={t('shop').toUpperCase()}
                    onClick={() => {
                        _getLink(
                            details.affiliate_link!
                                ? details.affiliate_link!
                                : details.site!
                        )
                    }}
                />
            </div>
            {showInfo && <Percentage hideInfo={_hideInfoModal} />}
        </div>
    )
}
export default Corporate_Details

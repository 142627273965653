import { Transaction } from './../../types/api'

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS'

export interface TransactionsState {
    transactions: Array<Transaction>
}

export interface SetTransactionsAction {
    type: typeof SET_TRANSACTIONS
    payload: Array<Transaction>
}

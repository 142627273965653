import Slider from 'react-slick'
import close from '../../assets/images/svgs/close.svg'
import { detailsSlider } from '../../services/constants'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import './styles.scss'

const CorporateDetailsSlider = () => {
    const dispatch = useAppDispatch()
    const { numberButton } = useAppSelector((state) => state.impact)
    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        autoplay: true,
        speed: 500,
        className: 'firsttt',
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: numberButton,
    }
    const _closeModal = () => {
        dispatch(changeScreen('Corporate Details'))
    }
    return (
        <div className="details-container-slider__wrapper">
            <Slider {...settings}>
                {detailsSlider.map((el: any, index: number) => {
                    if (el.title.title === 'Ratings') {
                        return (
                            <div
                                style={{ width: '100%' }}
                                key={el.text + index}
                            >
                                <div className="details-container__closee-wrapper">
                                    <img
                                        className="details-container__closee-close"
                                        onClick={_closeModal}
                                        src={close}
                                    />
                                </div>
                                <div className="details-container-slider_container">
                                    <img
                                        src={el?.img}
                                        className="details-container-slider_img"
                                    />
                                    <p className="details-container-slider_container_title_4">
                                        {el?.title?.title}
                                    </p>
                                </div>
                                <div className="details-container-slider_container_4">
                                    <div className="details-container-slider_container_4__content">
                                        <img
                                            className="details-container-slider_container_4__content_img"
                                            src={el.logos.logo1}
                                        />
                                        <div className="details-container-slider_container_4__content__text">
                                            <div
                                                className="details-container-slider_container_4__content__text__title"
                                                style={{ color: '#3E6331' }}
                                            >
                                                {el.p1.title}
                                            </div>
                                            <div className="details-container-slider_container_4__content__text__text">
                                                {el.p1.text}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-container-slider_container_4__content">
                                        <img
                                            className="details-container-slider_container_4__content_img"
                                            src={el.logos.logo2}
                                        />
                                        <div className="details-container-slider_container_4__content__text">
                                            <div
                                                className="details-container-slider_container_4__content__text__title"
                                                style={{ color: '#D3A04C' }}
                                            >
                                                {el.p2.title}
                                            </div>
                                            <div className="details-container-slider_container_4__content__text__text">
                                                {el.p2.text}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-container-slider_container_4__content">
                                        <img
                                            className="details-container-slider_container_4__content_img"
                                            src={el.logos.logo3}
                                        />
                                        <div className="details-container-slider_container_4__content__text">
                                            <div
                                                className="details-container-slider_container_4__content__text__title"
                                                style={{ color: '#9F9F9F' }}
                                            >
                                                {el.p3.title}
                                            </div>
                                            <div
                                                className="details-container-slider_container_4__content__text__text"
                                                style={{
                                                    alignSelf: 'flex-start',
                                                }}
                                            >
                                                {el.p3.text}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div
                                style={{ width: '100%' }}
                                key={el.text + index}
                            >
                                <div className="details-container-slider_container">
                                    <div className="details-container__closee-wrapper">
                                        <img
                                            className="details-container__closee-close"
                                            onClick={_closeModal}
                                            src={close}
                                        />
                                    </div>
                                    <img
                                        src={el?.img}
                                        className="details-container-slider_img"
                                    />
                                    <p className="details-container-slider_container_title">
                                        {el?.title?.title}
                                    </p>
                                    <p className="details-container-slider_container_subtitle">
                                        {el?.title?.subtitle}
                                    </p>
                                    <img
                                        src={el?.title?.img}
                                        className="details-container-slider_container_logo"
                                    />
                                </div>
                                <div className="details-container-slider__content">
                                    <p className="details-container-slider__content_title">
                                        {el?.text?.title}
                                    </p>
                                    <p className="details-container-slider__content_text">
                                        {el?.text?.text}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                })}
            </Slider>
        </div>
    )
}
export default CorporateDetailsSlider

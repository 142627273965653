import UserMenuHeader from '../../components/user-menu-header'
import { useAppDispatch } from '../../store/hooks'
import { changeScreen } from '../../store/auth/auth-action'
import { useMemo, useState } from 'react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import SettingsHeader from '../../components/settings-header'

const CalculatingIncome = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [age, setAge] = useState(35)
    const [retirementAge, setRetirementAge] = useState(65)
    const [contribution, setContribution] = useState(250)
    const [yieldPercentage, setYieldPercentage] = useState(7)

    const calculateFutureValue = () => {
        const yearsToRetirement = retirementAge - age
        const monthlyRate = yieldPercentage / 100 / 12
        const nPayments = yearsToRetirement * 12
        const future_value: number =
            contribution * (((1 + monthlyRate) ** nPayments - 1) / monthlyRate)
        return Math.round(future_value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const futureValue = useMemo(() => {
        return calculateFutureValue()
    }, [age, retirementAge, contribution, yieldPercentage])

    const _goBack = () => {
        dispatch(changeScreen('My Impact'))
    }

    return (
        <div className="calculate-income">
            <SettingsHeader
                screen="My Impact"
                text={t('my_savings').toUpperCase()}
            />
            <div className="calculate-income__content">
                {/* Sliders */}
                <div className="calculate-income__slider-group">
                    <div>
                        <div className="slider-label">
                            <label>{t('what_is_your_age')}</label>
                            <span>
                                {age} {t('years')}
                            </span>
                        </div>

                        <input
                            type="range"
                            min="18"
                            max="70"
                            value={age}
                            onChange={(e) => setAge(Number(e.target.value))}
                        />
                    </div>

                    <div>
                        <div className="slider-label">
                            <label>{t('when_will_you_retire')}</label>
                            <span>
                                {retirementAge} {t('years')}
                            </span>
                        </div>

                        <input
                            type="range"
                            min="55"
                            max="70"
                            value={retirementAge}
                            onChange={(e) =>
                                setRetirementAge(Number(e.target.value))
                            }
                        />
                    </div>

                    <div>
                        <div className="slider-label">
                            <label>{t('average_monthly_contribution')}</label>
                            <span>{contribution} SEK</span>
                        </div>
                        <input
                            type="range"
                            min="0"
                            max="2500"
                            step="50"
                            value={contribution}
                            onChange={(e) =>
                                setContribution(Number(e.target.value))
                            }
                        />
                    </div>

                    <div>
                        <div className="slider-label">
                            <label>{t('average_yield')}</label>
                            <span>{yieldPercentage}%</span>
                        </div>
                        <input
                            type="range"
                            min="4"
                            max="8"
                            step={0.5}
                            value={yieldPercentage}
                            onChange={(e) =>
                                setYieldPercentage(Number(e.target.value))
                            }
                        />
                    </div>
                </div>
                {/* Result */}
                <div className="calculate-income__result">
                    <h2>
                        {t('in')} {retirementAge - age} {t('years')}
                    </h2>

                    <div className="result-circle">
                        <p>{futureValue}</p>
                        <p>SEK</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalculatingIncome

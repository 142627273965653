import { AccessToken, User } from '../../types/api'

export interface AuthState {
    screen: string,
    access_token: AccessToken,
    user: User | null,
}

export const CHANGE_SCREEN = 'CHANGE_SCREEN'
export const SIGN_UP = 'SIGN_UP'
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_USER_INFO = 'SET_USER_INFO'
export const LOGOUT = 'LOGOUT'

interface SetAccessTokenAction {
    type: typeof SET_ACCESS_TOKEN
    payload: AccessToken,
}
interface ChangeScreenAction {
    type: typeof CHANGE_SCREEN
    payload: string,
}
interface SetUserInfoAction {
    type: typeof SET_USER_INFO
    payload: User,
}
interface LogOutAction {
    type: typeof LOGOUT,
}

export type AuthActions =
    | SetAccessTokenAction
    | ChangeScreenAction
    | SetUserInfoAction
    | LogOutAction

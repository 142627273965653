import { CorporatePartner } from '../../types/api'
import * as types from './types'

const initialState: types.FollowingState = {
    followings: {
        results: [],
        metadata: { total: 1 },
    },
    inScreen: false,
}
export const followingReducer = (
    state = initialState,
    action: types.FollowingActions
): types.FollowingState => {
    switch (action.type) {
        case types.SET_FOLLOWINGS:
            return { ...state, followings: action.payload }
        case types.IS_IN_SCREEN:
            return { ...state, inScreen: action.payload }
        case types.UNFOLLOW: {
            const newFollowings = state.followings?.results?.filter(
                (e: CorporatePartner) => e.id !== action.payload.id
            )
            return {
                ...state,
                followings: {
                    results: newFollowings,
                    metadata: {
                        ...state.followings.metadata,
                        total: state.followings.metadata.total - 1,
                    },
                },
            }
        }
        default:
            return state
    }
}

import { Project } from './../../types/api'
import * as types from './types'

const initialState: types.ImpactState = {
    impacts: [],
    activeProject: {} as Project,
    corporateId: '',
    numberButton: 0,
    error: false,
    inProject: false,
    isMore: false,
}

export const impactReducer = (
    state = initialState,
    action: types.ImpactActions
): types.ImpactState => {
    switch (action.type) {
        case types.SET_IMPACTS:
            return { ...state, impacts: action.payload }
        case types.SET_ACTIVE_PROJECT:
            return { ...state, activeProject: action.payload }
        case types.SET_CORPORATE_ID:
            return { ...state, corporateId: action.payload }
        case types.SET_NUMBER_BUTTON:
            return { ...state, numberButton: action.payload }
        case types.SET_ERROR:
            return { ...state, error: action.payload }
        case types.SET_IN_PROJECT:
            return { ...state, inProject: action.payload }
        case types.SET_MORE_INFO:
            return { ...state, isMore: action.payload }
        default:
            return state
    }
}

import { combineReducers } from 'redux'
import { authReducer } from './auth/auth-reducer'
import { followingReducer } from './following/following-reducer'
import { impactReducer } from './impact/impact-reducer'
import { transactionsReducer } from './transactions/transaction-reducer'

export const rootReducer = combineReducers({
    auth: authReducer,
    impact: impactReducer,
    transactions: transactionsReducer,
    following: followingReducer,
})

import { AnyAction } from 'redux'
import * as types from './types'

const initialState: types.TransactionsState = {
    transactions: [],
}

export const transactionsReducer = (
    state = initialState,
    action: AnyAction
): types.TransactionsState => {
    switch (action.type) {
        case types.SET_TRANSACTIONS:
            return { ...state, transactions: action.payload }
        default:
            return state
    }
}

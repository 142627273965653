import classNames from 'classnames'
import React from 'react'
import checkMark from '../../assets/images/svgs/check-mark.svg'
import shoppingBag from '../../assets/images/svgs/icon-shopping-bag.svg'
import arrow from '../../assets/images/svgs/icon-up-left.svg'
import star from '../../assets/images/svgs/star.svg'
import { Transaction } from '../../types/api'

import './styles.scss'

interface Props {
    item: Transaction
    border: boolean
}
export const TransactionItem: React.FC<Props> = ({ item, border }) => {
    let image: string
    let name: string
    let type: string | undefined

    const getValueOfTransaction = (status: string | null | undefined) => {
        
        if(!status) return
        
        let value = "Pending"
        if (status === 'Cleared') value = 'Confirmed'
        if (status === 'Declined') value = 'Canceled'

        return value
    }

    switch (item.type.id) {
        case 'signup':
        case 'first_purchase_card_linking':
        case 'referral':
        case 'first_purchase_affiliate':
        case 'card_linking':
        case 'share':
            image = checkMark
            name = item.type.name
            type = 'Bonus'
            break
        case 'donation': {
            const project = item.debit_account.project!
            let unit = project.unit
            if (project.name === 'REWILDING') {
                unit = 'm2'
            }
            image = star
            name = project.name
            type = `Impact ${project.user_total_units_amount}${unit} ${project.accumulation_units_plural}`
            break
        }
        case 'purchase':
            name = item.external_transaction!.corporate_partner.name
            image = shoppingBag
            break
        case 'refund':
            name = item.external_transaction!.corporate_partner.name
            type = 'Return'
            image = arrow
            break
        default:
            image = checkMark
            name = item.type.name
    }

    return (
        <div
            className={classNames('transaction-item', {
                'transaction-item--list': border,
            })}
        >
            <div className="transaction-item__info">
                <div className="transaction-item__title">
                    <img
                        alt="points"
                        src={image}
                        className="transaction-item__img"
                    ></img>
                    <span className="transaction-item__name">{name}</span>
                </div>
                <div className="transaction-item_points">
                    {item.credit_account.user
                        ? -item.credit_amount.amount/100
                        : '+' + item.debit_amount.amount/100}
                </div>
            </div>
            <div className="transaction-item__status">
                <div>
                    <span className="transaction-item__type">
                        {type ? type : item.type.name}
                    </span>
                    {item.external_transaction && (
                        <span className="transaction-item__amount">
                            {item.external_transaction.amount.amount / 100}{' '}
                            {item.external_transaction.amount.currency}
                        </span>
                    )}
                </div>
                <span
                    style={{
                        color:
                            item.external_transaction?.state.name === 'Declined'
                                ? '#ef3a41'
                                : '#94adce',
                    }}
                    className="transaction-item__state"
                >
                    {getValueOfTransaction(item.external_transaction?.state.name)}
                </span>
            </div>
        </div>
    )
}

import React from 'react'
import { Transaction } from '../../types/api'
import { TransactionItem } from '../transaction-item'

import './styles.scss'

interface Props {
    date: string
    elements: Transaction[]
}

export const TransactionDateContainer: React.FC<Props> = ({
    date,
    elements,
}) => {
    return (
        <div className="transaction-date-container">
            <div className="transaction-date-container__date-container">
                <div className="transaction-date-container__border"></div>
                <div className="transaction-date-container__title-wrapper">
                    <h3 className="transaction-date-container_title">{date}</h3>
                </div>
                <div className="transaction-date-container__border"></div>
            </div>
            <div className="transaction-date-container__transactions">
                {elements.map((el: Transaction) => (
                    <TransactionItem
                        key={el.id}
                        item={el}
                        border={elements.length > 1 ? true : false}
                    />
                ))}
            </div>
        </div>
    )
}

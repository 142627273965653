import React, { useEffect, useState } from 'react'

import checkbox from '../../assets/images/svgs/checkbox.svg'

import './styles.scss'
interface ItemType {
    id: string
    name: string
}
interface Props {
    item: ItemType
    selectedInterests: Array<string>
    addSelected: (arg: ItemType) => void
    removeSelected: (arg: ItemType) => void
}

export const InterestButton: React.FC<Props> = ({
    item,
    addSelected,
    removeSelected,
    selectedInterests,
}) => {
    const [isSelected, setSelected] = useState(false)
    useEffect(() => {
        setSelected(false)
        selectedInterests.map((e: string) => {
            if (e === item.id) {
                setSelected(true)
            }
        })
    })
    const _selected = () => {
        if (!isSelected) {
            addSelected(item)
        } else {
            removeSelected(item)
        }
    }
    return (
        <div
            className={`interest-button ${isSelected && 'active'}`}
            onClick={_selected}
        >
            <p className="interest-button_title">{item.name}</p>
            {isSelected && <img src={checkbox} />}
        </div>
    )
}

import { Project } from './../../types/api'

export const SET_IMPACTS = 'SET_IMPACTS'
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT'
export const SET_CORPORATE_ID = 'SET_CORPORATE_ID'
export const SET_NUMBER_BUTTON = 'SET_NUMBER_BUTTON'
export const SET_ERROR = 'SET_ERROR'
export const SET_IN_PROJECT = 'SET_IN_PROJECT'
export const SET_MORE_INFO = 'SET_MORE_INFO'

export interface ImpactState {
    impacts: Array<Project>
    activeProject: Project
    corporateId: string
    numberButton: number
    error: boolean
    inProject: boolean
    isMore: boolean
}

interface SetImpactsAction {
    type: typeof SET_IMPACTS
    payload: ImpactState['impacts']
}

interface SetActiveProjectAction {
    type: typeof SET_ACTIVE_PROJECT
    payload: Project
}

interface SetCorporateIdAction {
    type: typeof SET_CORPORATE_ID
    payload: string
}

interface SetNumberButtonAction {
    type: typeof SET_NUMBER_BUTTON
    payload: number
}

interface SetErrorAction {
    type: typeof SET_ERROR
    payload: boolean
}

interface SetInProjectAction {
    type: typeof SET_IN_PROJECT
    payload: boolean
}

interface SetMoreInfoAction {
    type: typeof SET_MORE_INFO
    payload: boolean
}

export type ImpactActions =
    | SetImpactsAction
    | SetActiveProjectAction
    | SetCorporateIdAction
    | SetNumberButtonAction
    | SetErrorAction
    | SetInProjectAction
    | SetMoreInfoAction

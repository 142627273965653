import React, { useState } from 'react'

import hide from '../../assets/images/svgs/hide-pass.svg'
import './styles.scss'

interface Prop {
    value: string
    setValue: (arg: string) => void
    label: string
}

export const PasswordField: React.FC<Prop> = ({ value, setValue, label }) => {
    const [type, setType] = useState(true)
    return (
        <div className="password-input-container">
            <input
                className="password-input-container_input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={label}
                type={type ? 'password' : 'text'}
            />
            <span
                className="password-input-container_img"
                onClick={() => {
                    setType(!type)
                }}
            >
                <img src={hide} />
            </span>
        </div>
    )
}

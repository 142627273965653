import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Route, Routes, useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'
import SearchIcon from '../../assets/images/svgs/search.svg'
import sweFlag from '../../assets/images/Flag_of_Sweden.png'
import nlFlag from '../../assets/images/Flag_of_the_Netherlands.png'
import langSvg from '../../assets/images/svgs/lang.svg'
import { Header } from '../../components/header'
import { Modal } from '../../components/modal'
import Photos from '../../components/photos'
import ShopScreenButton from '../../components/shop-screen-button'
import {
    filterCategories,
    filterLoginedCategories,
    getAllAdditionalDefaultPhotos,
    getAllAdditionalPhotos,
    getAllDefaultPhotos,
    getAllPhotos,
    getCategories,
    searchAll,
    searchDefault,
} from '../../services/api'
import { useAppSelector } from '../../store/hooks'
import { Category, BaseBrand, LoggedInBrand } from '../../types/api'
import { useTranslation } from 'react-i18next'
import './styles.scss'

let scrollWidth
const brandsCount = 36

const countryOptions = [
    { content: <span>ALL</span>, value: '' },
    { content: <img src={sweFlag} alt="SWE" />, value: 'SWE' },
    { content: <img src={nlFlag} alt="NL" />, value: 'NLD' },
]

const ShopScreen = () => {
    const { t, i18n } = useTranslation()
    const { user, access_token } = useAppSelector((state) => state.auth)
    const { error } = useAppSelector((state) => state.impact)
    const [photos, setPhotos] = useState<Array<BaseBrand | LoggedInBrand>>([])
    const [categories, setCategories] = useState<Array<Category>>([])
    const [activeCategory, setActiveCategory] = useState<any>({
        id: '1',
        name: 'All',
        sort_order: 0,
    })
    const [limit, _] = useState(brandsCount)
    const [offset, setOffset] = useState(brandsCount)
    const [total, setTotal] = useState<number>(brandsCount)
    const [text, setText] = useState('')
    const [filtersShow, __] = useState(false)
    const location = useLocation()
    const transitions = useTransition(location, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })
    const [countryValue, setCountryValue] = useState<string>('')
    const [countryContent, setCountryContent] = useState<
        string | React.ReactNode
    >(<span>ALL</span>)
    const [isCountrySelectorHidden, setIsCountrySelectorHidden] =
        useState<boolean>(true)
    const [isCountrySet, setIsCountrySet] = useState(false)

    useEffect(() => {
        const determineCountry = async () => {
            const language = navigator.language

            if (language?.startsWith('nl')) {
                setCountryContent(<img src={nlFlag} alt="NL Flag" />)
                setCountryValue('NLD')
            } else if (language?.startsWith('sw')) {
                setCountryContent(<img src={sweFlag} alt="SWE Flag" />)
                setCountryValue('SWE')
            } else {
                setCountryContent(<span>ALL</span>)
                setCountryValue('')
            }

            setIsCountrySet(true)
        }

        determineCountry()
    }, [])

    const _search = useCallback(async () => {
        setPhotos([])
        if (text.length === 0) {
            if (activeCategory.id === '1') {
                setActiveCategory({ id: '1', name: 'All', sort_order: 0 })
            } else {
                const data = await _filterCategories(
                    activeCategory.id,
                    limit,
                    0,
                    countryValue
                )
                setPhotos(data)
            }
        } else {
            if (user) {
                const data = await searchAll(
                    access_token.access_token,
                    text,
                    limit,
                    0,
                    countryValue
                )
                setPhotos(data)
            } else {
                const data = await searchDefault(text, limit, 0, countryValue)
                setPhotos(data)
            }
        }
    }, [activeCategory, text, countryValue])

    const search_delete = () => {
        setText('')
        setActiveCategory({ id: '1', name: 'All', sort_order: 0 })
    }

    const _inputSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode === 13) {
            _search()
        }
    }
    const _getAllCategories = useCallback(async () => {
        const data = await getCategories()
        setCategories(data)
    }, [categories])

    const _getAllPhotos = useCallback(async () => {
        let data
        if (user) {
            data = await getAllPhotos(
                access_token.access_token,
                limit,
                0,
                countryValue
            )
        } else {
            data = await getAllDefaultPhotos(
                access_token.access_token,
                limit,
                0,
                countryValue
            )
        }
        setPhotos(data.results)
        setTotal(data.metadata.total)
    }, [photos, user, access_token, countryValue])

    useEffect(() => {
        _getAllCategories()
    }, [])

    useEffect(() => {
        if (isCountrySet) {
            _getFilteredPhotoByCategory()
        }
    }, [activeCategory, countryValue, isCountrySet])

    const _filterCategories = useCallback(
        async (
            id: string,
            limit: number,
            offset: number,
            countryValue: string
        ) => {
            let data: any = []
            if (user) {
                data = await filterLoginedCategories(
                    id,
                    limit,
                    offset,
                    access_token.access_token,
                    countryValue
                )
            } else {
                data = await filterCategories(id, limit, offset, countryValue)
            }
            return data
        },
        [user, categories, activeCategory, countryValue]
    )

    const _getAllAdditionalPhoto = async () => {
        let data: Array<BaseBrand | LoggedInBrand> = []

        if (!text) {
            if (activeCategory.name !== 'All') {
                if (user) {
                    data = await filterLoginedCategories(
                        activeCategory.id,
                        limit,
                        offset,
                        access_token.access_token,
                        countryValue
                    )
                } else {
                    data = await filterCategories(
                        activeCategory.id,
                        limit,
                        offset,
                        countryValue
                    )
                }
            } else {
                if (user) {
                    data = await getAllAdditionalPhotos(
                        access_token.access_token,
                        limit,
                        offset,
                        countryValue
                    )
                } else {
                    data = await getAllAdditionalDefaultPhotos(
                        access_token.access_token,
                        limit,
                        offset,
                        countryValue
                    )
                }
            }
        } else {
            if (user) {
                data = await searchAll(
                    access_token.access_token,
                    text,
                    limit,
                    offset,
                    countryValue
                )
            } else {
                data = await searchDefault(text, limit, offset, countryValue)
            }
        }
        setPhotos((prevPhotos) => [...prevPhotos, ...data])
    }

    const fetchData = async () => {
        await _getAllAdditionalPhoto()
        setOffset((prevOffset) => prevOffset + brandsCount)
    }
    const _chooseItem = (el: Category) => {
        setPhotos([])
        setOffset(0)
        setActiveCategory(el)
    }

    const _getFilteredPhotoByCategory = async () => {
        setPhotos([])
        if (activeCategory.id == 1) {
            setOffset(brandsCount)
            _getAllPhotos()
        } else {
            const data = await _filterCategories(
                activeCategory.id,
                limit,
                0,
                countryValue
            )
            setPhotos(data)
            setOffset(brandsCount)
        }
    }

    const _getCategoryNameByLanguage = (category: Category) => {
        const currentLanguage = i18n.language

        switch (currentLanguage) {
            case 'se':
                return category.name_swe
            case 'nl':
                return category.name_nld
            default:
                return category.name
        }
    }

    const _handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value)
    }

    const _countrySelectHandler = (
        content: React.ReactNode | string,
        value: string
    ) => {
        setCountryValue(value)
        setCountryContent(content)
        setIsCountrySelectorHidden(true)
    }

    useEffect(() => {
        const div = document.createElement('div')
        div.style.overflowY = 'scroll'
        div.style.width = '50px'
        div.style.height = '50px'
        document.body.append(div)
        scrollWidth = div.offsetWidth - div.clientWidth
        div.remove()
    }, [])

    return (
        <InfiniteScroll
            dataLength={photos ? photos.length : brandsCount}
            next={fetchData}
            hasMore={photos.length < total}
            loader={null}
            endMessage={null}
            scrollableTarget="scrollableDiv"
        >
            <div
                id="content"
                className={
                    location.pathname !== '/'
                        ? 'shop-screen_disabled'
                        : 'shop-screen'
                }
            >
                <Header />
                {error ? (
                    <div
                        style={{
                            fontSize: '26px',
                            color: 'red',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {t('something_went_wrong')}
                    </div>
                ) : (
                    <>
                        <div className="shop-screen__input-container">
                            <div className="shop-screen__inputs">
                                <div className="shop-screen__input">
                                    <img
                                        className="shop-screen_icon"
                                        src={SearchIcon}
                                    />

                                    <input
                                        className=""
                                        type="text"
                                        value={text}
                                        onChange={_handlerChange}
                                        placeholder={t('search_brands')}
                                        autoComplete="off"
                                        onKeyPress={_inputSearch}
                                    />

                                    <span
                                        onClick={search_delete}
                                        className="shop-screen_search-delete"
                                        style={{
                                            visibility: text
                                                ? 'visible'
                                                : 'hidden',
                                            pointerEvents: text
                                                ? 'auto'
                                                : 'none',
                                        }}
                                    >
                                        X
                                    </span>

                                    <ShopScreenButton
                                        text={t('search')}
                                        _className={'shop-screen-button-search'}
                                        id={'20'}
                                        onClick={_search}
                                    />
                                </div>

                                {/* Country selector */}
                                <div
                                    onMouseEnter={() => {
                                        setIsCountrySelectorHidden(false)
                                    }}
                                    onMouseLeave={() => {
                                        setIsCountrySelectorHidden(true)
                                    }}
                                    className="shop-screen__country-selector"
                                >
                                    <button
                                        onClick={() => {
                                            setIsCountrySelectorHidden(false)
                                        }}
                                    >
                                        <img
                                            className="selector-icon"
                                            src={langSvg}
                                            alt="Country"
                                        />
                                        {countryContent
                                            ? countryContent
                                            : 'All'}
                                    </button>
                                    <ul
                                        style={{
                                            display: isCountrySelectorHidden
                                                ? 'none'
                                                : 'flex',
                                        }}
                                    >
                                        {countryOptions.map((options) => (
                                            <li
                                                key={options.value + 'country'}
                                                onClick={() => {
                                                    _countrySelectHandler(
                                                        options.content,
                                                        options.value
                                                    )
                                                }}
                                            >
                                                {options.content}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="shop-screen__buttons">
                                <ShopScreenButton
                                    key={'all-btn'}
                                    id={'1'}
                                    text={t('all')}
                                    el={{
                                        id: '1',
                                        name: 'All',
                                        sort_order: 0,
                                        name_nld: '',
                                        name_swe: '',
                                    }}
                                    _className={
                                        activeCategory.name === 'All'
                                            ? 'shop-screen-button-active'
                                            : 'shop-screen-button'
                                    }
                                    onClick={_chooseItem}
                                />

                                {categories?.map((e: Category) => {
                                    return (
                                        <ShopScreenButton
                                            key={e.id}
                                            id={e.id}
                                            el={e}
                                            text={_getCategoryNameByLanguage(e)}
                                            _className={
                                                activeCategory.name === e.name
                                                    ? 'shop-screen-button-active'
                                                    : 'shop-screen-button'
                                            }
                                            onClick={_chooseItem}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        <div className="shop-screen__photos-container">
                            <div className="shop-screen__photos">
                                {photos &&
                                    photos.map(
                                        (
                                            e: BaseBrand | LoggedInBrand,
                                            index: number
                                        ) => {
                                            return (
                                                <Photos
                                                    key={`${e.name}-${index}`}
                                                    data={e}
                                                    text={text}
                                                    isFiltersShow={filtersShow}
                                                />
                                            )
                                        }
                                    )}
                            </div>
                        </div>
                    </>
                )}

                {transitions((props, item) => (
                    <animated.div style={props} className="animated">
                        <Routes location={item}>
                            {/* //* Routes for deffault language (en) */}
                            <Route path="/menu" element={<Modal />} />
                            <Route
                                path="/brand/:brandName"
                                element={<Modal />}
                            />

                            {/* //* Routes for certain language (se, nl) */}
                            <Route path="/:lang/menu" element={<Modal />} />
                            <Route
                                path="/:lang/brand/:brandName"
                                element={<Modal />}
                            />

                            {/* //* Routes for the main page depending on the language */}
                            <Route path="/:lang/*" element={false} />
                            <Route path="/" element={false} />
                        </Routes>
                    </animated.div>
                ))}
            </div>
        </InfiniteScroll>
    )
}

export default ShopScreen

import { CorporatePartner } from '../../types/api'

export const SET_FOLLOWINGS = 'SET_FOLLOWINGS'
export const IS_IN_SCREEN = 'IS_IN_SCREEN'
export const UNFOLLOW = 'UNFOLLOW'

export interface Followings {
    results: Array<CorporatePartner>
    metadata: { total: number }
}

export interface FollowingState {
    followings: Followings
    inScreen: boolean
}

interface SetFollowingsAction {
    type: typeof SET_FOLLOWINGS
    payload: Followings
}

interface IsInScreenAction {
    type: typeof IS_IN_SCREEN
    payload: boolean
}

interface UnFollowAction {
    type: typeof UNFOLLOW
    payload: CorporatePartner
}

export type FollowingActions =
    | SetFollowingsAction
    | IsInScreenAction
    | UnFollowAction

import { AccessToken } from './../../types/api'
import * as types from './types'

const initialState: types.AuthState = {
    screen: 'Start Screen',
    access_token: {} as AccessToken,
    user: null,
}

export const authReducer = (
    state = initialState,
    action: types.AuthActions
): types.AuthState => {
    switch (action.type) {
        case types.CHANGE_SCREEN:
            return { ...state, screen: action.payload }
        case types.SET_ACCESS_TOKEN:
            return { ...state, access_token: action.payload }
        case types.SET_USER_INFO:
            return { ...state, user: action.payload }
        case types.LOGOUT:
            return { ...state, user: null, access_token: {} as AccessToken }
        default:
            return state
    }
}

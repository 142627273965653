import React from 'react'
import { Category } from '../../types/api'
import './styles.scss'

interface Props {
    text: string
    _className: string
    onClick: (el: any) => void
    id: string
    el?: Category
}
const ShopScreenButton: React.FC<Props> = ({
    text,
    _className,
    onClick,
    id,
    el,
}) => {
    const _onClick = () => {
        onClick(el)
    }
    return (
        <button key={id} className={_className} onClick={_onClick}>
            {text}
        </button>
    )
}
export default ShopScreenButton

import React from 'react'
import { Button } from '../button'
import { useTranslation } from 'react-i18next'
import './styles.scss'

interface Props {
    hideInfo: () => void
}

const Percentage: React.FC<Props> = ({ hideInfo }) => {
    
    const {t} = useTranslation()
    
    return (
        <div className="percentage-info-container">
            <div className="percentage-info-container__modal">
                <h3 className="percentage-info-container_title">{t("percentage")}</h3>
                <p className="percentage-info-container_desc">
                   {t("maximum_contribution")}
                </p>
                <p className="percentage-info-container_desc">
                    {t("different_fees")}
                </p>
                <p className="percentage-info-container_desc">
                    {t("example_contribution")}
                </p>

                <Button variant="white-outlined" text="OK" onClick={hideInfo} />
            </div>
        </div>
    )
}

export default Percentage
